import {
  PROJECT_STATUSES,
  PROJECT_FEATURE_FLAGS,
} from "@/components/constants.js"

export default {
  name: {
    component: "TextFieldSearchFilter",
  },
  exid: {
    component: "TextFieldSearchFilter",
  },
  cameras: {
    component: "TextFieldSearchFilter",
  },
  status: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ value: "", name: "All" }, ...PROJECT_STATUSES],
    },
  },
  featureFlags: {
    component: "SelectSearchFilter",
    attributes: {
      items: PROJECT_FEATURE_FLAGS,
      multiple: true,
    },
  },
}
