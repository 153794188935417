import { PROJECT_STATUSES_IDS_CLASSES } from "@/components/constants.js"

export default {
  name: "ProjectUtils",
  methods: {
    getProjectStatusColor(status) {
      return PROJECT_STATUSES_IDS_CLASSES[status]?.class || ""
    },
    getProjectStatusText(status) {
      return PROJECT_STATUSES_IDS_CLASSES[status]?.value || status
    },
  },
}
